<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>Manage authorised people</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <h5>The following people have permission to authorise purchase orders</h5>
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(authoriser, index) in poAuthorisers" :key="`au_${index}`">
              <td>{{ authoriser.user.name }}</td>
              <td class="text-right">
                <v-btn x-small @click="removePermission(1, index)" color="red">Revoke permission</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-select
          v-model="newPoAuthoriser"
          label="Add person to list of people with permission to authorse a PO"
          :items="users"></v-select>
        <v-btn @click="saveAuthoriser('PO')">Save</v-btn>
      </v-col>
      <v-col class="col-6">
        <h5>The following people have permission to authorise quotations (when the BOQs have been re-priced)</h5>
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(authoriser, index) in quoteAuthorisers" :key="`au_${index}`">
              <td>{{ authoriser.user.name }}</td>
              <td class="text-right">
                <v-btn x-small @click="removePermission(2, index)" color="red">Revoke permission</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-select
          v-model="newQuoteAuthoriser"
          label="Add person to list of people with permission to authorse a Quotation"
          :items="users"></v-select>
        <v-btn @click="saveAuthoriser('Quote')">Save</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminAuthorisers',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      poAuthorisers: [],
      quoteAuthorisers: [],
      users: [],
      newPoAuthoriser: 0,
      newQuoteAuthoriser: 0,
    };
  },
  methods: {
    saveAuthoriser(type) {
      const postData = {};
      if (type === 'PO') postData.user_id = this.newPoAuthoriser;
      if (type === 'Quote') postData.user_id = this.newQuoteAuthoriser;
      postData.type = type;
      axios.post(`/authorisers/create.json?token=${this.token}`, postData)
				.then(() => {
					this.getAuthorisers();
          this.newPoAuthoriser = 0;
          this.newQuoteAuthoriser = 0;
				})
				.catch((error) => {
					console.log(error);
				});
    },
    getAuthorisers() {
      axios.get(`/authorisers/getAll.json?token=${this.token}`)
				.then((response) => {
					this.poAuthorisers = response.data.poAuthorisers;
          this.quoteAuthorisers = response.data.quoteAuthorisers;
				})
				.catch((error) => {
					console.log(error);
				});
    },
    getUsers() {
      axios.get(`/users/getSelectList.json?token=${this.token}`)
				.then((response) => {
					this.users = response.data.users;
				})
				.catch((error) => {
					console.log(error);
				});
    },
    removePermission(type, index) {
      let authoriser = {};
      if (type === 1) authoriser = this.poAuthorisers[index];
      if (type === 2) authoriser = this.quoteAuthorisers[index];
      axios.get(`/authorisers/revoke/${authoriser.id}.json?token=${this.token}`)
				.then(() => {
					if (type === 1) this.poAuthorisers.splice(index, 1);
          if (type === 2) this.quoteAuthorisers.splice(index, 1);
				})
				.catch((error) => {
					console.log(error);
				});
    },
  },
  mounted() {
    this.getAuthorisers();
    this.getUsers();
  },
};
</script>
